import { Drawer } from "devextreme-react";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Toolbar from "../components/toolbar/Toolbar";
import Home from "../pages/home/Home";
import Settings from "../pages/settings/Settings";
import { Users } from "../pages/users/Users";
import { selectDrawerOpened } from "../redux/appSlice";
import NavigationList from "./NavigationList";

const NavigationDrawer = () => {
  const drawerOpened = useSelector(selectDrawerOpened);

  return (
    <div>
      <Toolbar />
      <Drawer
        component={NavigationList}
        opened={drawerOpened}
        openedStateMode={"shrink"}
        position={"left"}
        revealMode={"slide"}
        height="100vh"
        width={"100%"}
        className="drawer"
      >
        <div id="view">
          <Routes>
            <Route
              path="/home"
              element={
                <RequireAuth redirectTo="/home">
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth redirectTo="/home">
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireAuth redirectTo="/home">
                  <Settings />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </Drawer>
    </div>
  );
};

function RequireAuth({ children, redirectTo }) {
  let modules = true;
  let location = useLocation();

  if (modules) return children;

  return <Navigate to={redirectTo} state={{ from: location }} replace />;
}

export default NavigationDrawer;
