import List from "devextreme-react/list";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUsername } from "../redux/appSlice";
import "./Navigation.css";

const NavigationList = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const username = useSelector(selectUsername);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const navigation = [
    {
      id: 1,
      text: "Home",
      icon: "material-icons-outlined ic-home",
      path: "/home",
    },
    {
      id: 2,
      text: "Users",
      icon: "material-icons-outlined ic-people",
      path: "/users",
    },
    {
      id: 3,
      text: "Settings",
      icon: "material-icons-outlined ic-settings",
      path: "/settings",
    },

    // {
    //   id: 4,
    //   text: "Help & Feedback",
    //   icon: "material-icons-outlined ic-help",
    //   path: "/help",
    // },
    {
      id: 4,
      text: `${localStorage.getItem("username")}`,
      icon: "material-icons-outlined ic-user",
    },
    {
      id: 5,
      text: "Log out",
      icon: "material-icons-outlined ic-logout",
      path: "/login",
    },
  ];

  const loadView = (e) => {
    try {
      navigate(e.addedItems[0].path);
    } catch (error) {}
  };

  useEffect(() => {
    let run = true;
    // const data = [getPageIndex(location)];
    if (run) setSelectedKeys([0]);

    return () => {
      run = false;
    };
  }, [location]);

  const renderItem = (data) => {
    return (
      <div>
        <div className="listItem">
          <i className={`${data.icon} drawerIcons`}></i>
          <p>{data.text}</p>
        </div>
      </div>
    );
  };
  const handleSelectionChange = (e) => {
    if (e.name === "selectedItemKeys") {
    }
  };

  return (
    <div className="navigationListWrapper">
      <List
        keyExpr="id"
        dataSource={navigation}
        width={200}
        selectionMode="single"
        onSelectionChanged={loadView}
        itemRender={renderItem}
        elementAttr={{
          class: "panel-list",
        }}
        selectedItemKeys={selectedKeys}
        onOptionChanged={handleSelectionChange}
      />
    </div>
  );
};

export default NavigationList;
