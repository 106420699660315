import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    user: {
      token: localStorage.getItem("token") || null,
      username: localStorage.getItem("username") || null,
      role: "user",
    },
    drawerOpened: true,
    newFilePopup: false,
    theme:
      localStorage.getItem("currentTheme") || "material.purple.light.compact",
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserToken: (state, action) => {
      state.user.token = action.payload;
      state.user.username = localStorage.getItem("username") || null;
      if (action.payload) {
        localStorage.setItem("token", action.payload);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        state.user.username = null;
        state.user.role = "user";
      }
    },
    setUserName: (state, action) => {
      state.user.username = action.payload;
    },
    setUserRole: (state, action) => {
      state.user.role = action.payload;
    },
    setDrawerOpened: (state, action) => {
      if (action.payload) {
        state.drawerOpened = action.payload;
      } else {
        state.drawerOpened = !state.drawerOpened;
      }
    },
    setNewFilePopup: (state, action) => {
      state.newFilePopup = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem("currentTheme", action.payload);
    },
  },
});

export const {
  setUserToken,
  setUserRole,
  setUserName,
  setDrawerOpened,
  setNewFilePopup,
  setTheme,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsername = (state) => state.app.user.username;
export const selectUserRole = (state) => state.app.user.role;
export const selectUserToken = (state) => state.app.user.token;

export const selectDrawerOpened = (state) => state.app.drawerOpened;
export const selectNewFilePopup = (state) => state.app.newFilePopup;
export const selectTheme = (state) => state.app.theme;

export default appSlice.reducer;
