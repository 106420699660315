import React, { useEffect, useState } from "react";
import { FileManagerComponent as OTA } from "@senlab/ota_lib";
import { API_URL } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { selectNewFilePopup, setNewFilePopup } from "../../redux/appSlice";
const Home = () => {
  // const [newFilePopupOpened, setNewFilePopupOpened] = useState(false);
  const newFilePopupOpened = useSelector(selectNewFilePopup);
  const dispatch = useDispatch();

  const setNewFilePopupOpened = (bool) => {
    dispatch(setNewFilePopup(bool));
  };

  useEffect(() => {}, [newFilePopupOpened]);

  return (
    <div className="home-container">
      <OTA
        url={API_URL}
        newFilePopupOpened={newFilePopupOpened}
        setNewFilePopupOpened={setNewFilePopupOpened}
        role={"superadmin"}
        height="calc(100vh - 100px)"
      />
    </div>
  );
};

export default Home;
