import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setDrawerOpened, setNewFilePopup } from "../../redux/appSlice";

import ToolBarOriginal from "devextreme-react/toolbar";

import {
  createFolder,
  uploadFile,
  renameFile,
  deleteFile,
  downloadFile,
  moveFile,
  copyFile,
  clearSelection,
  refreshFileManager,
} from "@senlab/ota_lib";

import {
  detailsLayoutMenuOptions,
  getPageName,
  toolbarItemsVisibility,
} from "../../Utils/Utils";

import logo from "../../assets/SenLab.png";
import { addUsersRow } from "../../pages/users/Users";

const Toolbar = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const [currentPageTitle, setCurrentPageTitle] = useState("");
  const [toolbarItems, setToolbarItems] = useState({});

  useEffect(() => {
    setCurrentPageTitle(getPageName(location));
    setToolbarItems(toolbarItemsVisibility(location));
  }, [location]);

  const TOOLBAR_ITEMS = [
    {
      widget: "dxButton",
      location: "before",
      options: {
        icon: "material-icons-outlined ic-menu",
        onClick: () => {
          dispatch(setDrawerOpened());
        },
      },
    },
    {
      widget: "dxButton",
      location: "before",
      template: `<img src='${logo}' id='toolbar-logo'/>`,
    },
    {
      text: `${currentPageTitle}`,
      location: "center",
    },

    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "add",
        hint: "Add File",
        visible: toolbarItems.add,
      },
      onClick: () => {
        if (location.pathname === "/users") return addUsersRow();
        dispatch(setNewFilePopup(true));
      },
    },

    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "newfolder",
        hint: "New directory",
        visible: toolbarItems.addDir,
        onClick: () => {
          createFolder();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "material-icons-outlined ic-upload",
        hint: "Upload files",
        visible: toolbarItems.upload,
        onClick: () => {
          uploadFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "material-icons-outlined ic-edit",
        hint: "Rename",
        visible: toolbarItems.rename,
        onClick: () => {
          renameFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "material-icons-outlined ic-delete",
        hint: "Delete",
        visible: toolbarItems.delete,
        onClick: () => {
          deleteFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "material-icons-outlined ic-download",
        hint: "Download",
        visible: toolbarItems.download,
        onClick: () => {
          downloadFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "movetofolder",
        hint: "Move",
        visible: toolbarItems.move,
        onClick: () => {
          moveFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "copy",
        hint: "Copy",
        visible: toolbarItems.copy,
        onClick: () => {
          copyFile();
        },
      },
    },

    {
      widget: "dxButton",
      location: "after",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        hint: "Refresh",
        visible: toolbarItems.refresh,
        onClick: () => {
          refreshFileManager();
        },
      },
    },
    {
      location: "after",
      widget: "dxDropDownButton",
      locateInMenu: "auto",
      options: {
        displayExpr: "name",
        keyExpr: "id",
        selectedItemKey: 1,
        hint: "Details view",
        stylingMode: "text",
        useSelectMode: true,
        items: detailsLayoutMenuOptions,
        visible: toolbarItems.layout,
      },
    },
  ];

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: 999 }}>
      <ToolBarOriginal items={TOOLBAR_ITEMS} className="navBar" />
    </div>
  );
};

export default Toolbar;
