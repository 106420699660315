import {
  showDetailsFileManager,
  showThumbnailsFileManager,
} from "@senlab/ota_lib";

export const getPageName = (location) => {
  if (location.pathname === "/home") return "OTA";
  if (location.pathname === "/users") return "Users";
  if (location.pathname === "/settings") return "Settings";
  return "";
};

export const toolbarItemsVisibility = (location) => {
  let visibility = {
    add: false,
    addDir: false,
    upload: false,
    rename: false,
    delete: false,
    download: false,
    move: false,
    copy: false,
    refresh: false,
    layout: false,
  };
  if (location.pathname === "/home") {
    Object.keys(visibility).forEach((k) => (visibility[k] = true));
  } else if (location.pathname === "/users") {
    Object.keys(visibility).forEach((k) =>
      k === "add" ? (visibility[k] = true) : (visibility[k] = false)
    );
  }
  return visibility;
};

export const detailsLayoutMenuOptions = [
  {
    id: 1,
    icon: "detailslayout",
    onClick: () => {
      showDetailsFileManager();
    },
  },
  {
    id: 2,
    icon: "smalliconslayout",
    onClick: () => {
      showThumbnailsFileManager();
    },
  },
];
