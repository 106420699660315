import "./Login.css";
import { useEffect, useState } from "react";
import { Button, TextBox } from "devextreme-react";
import themes from "devextreme/ui/themes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../redux/appSlice";
import API from "../../api/api";
import Logo from "../../assets/SenLab.png";

import { Validator, RequiredRule } from "devextreme-react/validator";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("Error");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const onChangeUsername = (e) => {
    setUsername(e);
  };
  const onChangePassword = (e) => {
    setPassword(e);
  };

  const sendCredentials = async () => {
    setError("Error");
    try {
      if (username.length == 0 || password.length == 0) {
        return;
      }
      const credentials = { username, password };
      const data = await API.postRequest("/login", credentials);
      if (data.accessToken) {
        localStorage.setItem("username", username);

        dispatch(setUserToken(data.accessToken));
        navigate("/home", { replace: true });
      }
    } catch (error) {
      console.error("ERROR", error);
      if (error.response && error.response.status === 403) {
        setError("Invalid credentials");
      } else {
        setError("Server error");
      }
      dispatch(setUserToken(null));
    }
  };

  useEffect(() => {
    themes.current("material.purple.light.compact");
    dispatch(setUserToken(null));
  }, []);
  return (
    <div className="login">
      <div className="dx-card">
        <img src={Logo} />
        <TextBox
          name="username"
          showClearButton={true}
          onValueChange={onChangeUsername}
          width="100%"
          placeholder="Enter username"
          onEnterKey={sendCredentials}
        >
          <Validator>
            <RequiredRule />
          </Validator>
        </TextBox>
        <TextBox
          name="password"
          showClearButton={true}
          mode="password"
          onValueChange={onChangePassword}
          width="100%"
          placeholder="Enter password"
          onEnterKey={sendCredentials}
        >
          <Validator>
            <RequiredRule />
          </Validator>
        </TextBox>
        <p
          style={{
            color: "red",
            visibility: error === "Error" ? "hidden" : "visible",
          }}
        >
          {error}
        </p>

        <Button
          stylingMode="contained"
          type="default"
          onClick={sendCredentials}
          text="Sign in"
          className="loginBtn"
          width="100%"
          height="15%"
          useSubmitBehavior={true}
        ></Button>
      </div>
    </div>
  );
};

export default Login;
