import { Switch } from "devextreme-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme, setTheme } from "../../redux/appSlice";
import "./Settings.css";
const REACT_APP_APP_VERSION = "1.1.221202";

const Settings = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  const onValueChanged = (e) => {
    let chosenTheme = e.value
      ? "material.purple.dark.compact"
      : "material.purple.light.compact";

    dispatch(setTheme(chosenTheme));
  };
  return (
    <div className="settings-container">
      <div className="settings-row">
        <p>Dark mode</p>
        <Switch
          onValueChanged={onValueChanged}
          defaultValue={theme == "material.purple.dark.compact" ? true : false}
        />
      </div>

      <div className="settings-row">
        <p>Version</p>
        <p>{REACT_APP_APP_VERSION}</p>
      </div>
    </div>
  );
};

export default Settings;
