import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import NavigationDrawer from "./navigation/NavigationDrawer";
import Login from "./pages/login/Login";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme, selectUserToken, setUserRole } from "./redux/appSlice";
import API from "./api/api";
import themes from "devextreme/ui/themes";
import { LoadPanel } from "devextreme-react/load-panel";

function App() {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const token = useSelector(selectUserToken);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      API.getRequest(`/role`)
        .then((data) => {
          dispatch(setUserRole(data));
          setLoading(false);
        })
        .catch((err) => {
          dispatch(setUserRole("user"));
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    themes.current(theme);
  }, [theme]);

  if (loading) {
    return (
      <div className="App initPage">
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: `.App` }}
          onHiding={() => {
            setLoading(false);
          }}
          visible={loading}
          showIndicator={true}
          shading={false}
          showPane={true}
          closeOnOutsideClick={false}
          container={`.App`}
        />
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <RequireAuth redirectTo="/login">
              <NavigationDrawer />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = useSelector(selectUserToken);
  let location = useLocation();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} replace />
  );
}

export default App;
