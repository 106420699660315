import {
  DataGrid,
  Column,
  Editing,
  FilterRow,
  SearchPanel,
  Scrolling,
  StateStoring,
  Pager,
  Paging,
  Export,
  Grouping,
  GroupPanel,
  Lookup,
  Button,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import React, { useEffect, useRef, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import API from "../../api/api";

let USERS_GRID = { instance: null };

export const addUsersRow = () => {
  USERS_GRID.instance.addRow();
};

export const exportUsersRows = (selectedOnly = false) => {
  USERS_GRID.exportSelectedRows = selectedOnly;
  USERS_GRID.instance.exportToExcel();
};

export const showUsersColumnChooser = () => {
  USERS_GRID.instance.showColumnChooser();
};

export const searchByTextUsers = (e) => {
  USERS_GRID.instance.searchByText(e.value);
};

export const Users = () => {
  const dataGrid = useRef(null);
  const [role, setRole] = useState("user");
  useEffect(() => {
    API.getRequest(`/role`)
      .then((data) => setRole(data))
      .catch((err) => setRole("user"));
  }, []);

  const store = new CustomStore({
    key: "id",
    load: async function () {
      return await API.getRequest(`/users`);
    },
    insert: async function (values) {
      return await API.postRequest(`/addUser`, values);
    },
    update: async function (key, values) {
      return await API.postRequest(`/updateUser`, { id: key, data: values });
    },
    remove: async function (key) {
      return await API.deleteRequest(`/removeUser/${key}`);
    },
  });

  const onContentReady = (e) => {
    e.component.columnOption("command:edit", "visibleIndex", -1);
    USERS_GRID.instance = e.component;
  };
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      selectedRowsOnly: USERS_GRID.exportSelectedRows,
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Users.csv"
        );
      });
    });
    e.cancel = true;
  };
  const pagerOptions = {
    allowedPageSizes: [10, 50, "all"],
    displayMode: "full",
    showPageSizeSelector: true,
    showInfo: true,
    showNavButtons: true,
  };

  const column_user_role = [
    { id: 1, user_role: "superadmin", display: "Super admin" },
    { id: 2, user_role: "admin", display: "Admin" },
    { id: 3, user_role: "user", display: "User" },
    { id: 5, user_role: "api", display: "Api" },
  ];

  const onEditorPreparing = (e) => {
    if (e.parentType == "dataRow" && e.dataField == "password") {
      e.editorOptions.mode = "password";
    }
  };

  return (
    <div>
      <DataGrid
        id="dataGrid"
        dataSource={store}
        ref={dataGrid}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnChooser={{ enabled: false, mode: "select" }}
        onContentReady={onContentReady}
        showBorders={true}
        showColumnLines={true}
        onExporting={onExporting}
        columnHidingEnabled={false}
        onEditorPreparing={onEditorPreparing}
      >
        <Export enabled={false} allowExportSelectedData={true} />
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={pagerOptions.allowedPageSizes}
          displayMode={pagerOptions.displayMode}
          showPageSizeSelector={pagerOptions.showPageSizeSelector}
          showInfo={pagerOptions.showInfo}
          showNavigationButtons={pagerOptions.showNavButtons}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="usersView"
        />
        <Editing
          mode="popup"
          useIcons={true}
          allowUpdating={role === "superadmin" ? true : false}
          allowDeleting={role === "superadmin" ? true : false}
          allowAdding={false}
        />
        <Column type="buttons">
          <Button name="edit" icon="material-icons-outlined ic-edit" />
          <Button name="delete" icon="material-icons-outlined ic-delete" />
        </Column>
        <Column dataField="username" validationRules={[{ type: "required" }]} />
        <Column
          dataField="password"
          validationRules={[{ type: "required" }]}
          visible={false}
          formItem={{ visible: true }}
        />
        {/* <Column dataField="root" validationRules={[{ type: "required" }]}>
          <Lookup
            dataSource={rootOptions}
            valueExpr="root"
            displayExpr="root"
          />
        </Column> */}
        <Column
          dataField="user_role"
          caption="Role"
          validationRules={[{ type: "required" }]}
        >
          <Lookup
            dataSource={column_user_role}
            valueExpr="user_role"
            displayExpr="user_role"
          />
        </Column>

        <Column
          dataField="group_id"
          validationRules={[{ type: "required" }]}
          dataType="number"
        />

        <FilterRow visible={true} />
        <SearchPanel visible={false} />
        <Scrolling columnRenderingMode="virtual" rowRenderingMode="virtual" />
        <Grouping contextMenuEnabled={true} />
        <GroupPanel visible={false} />
      </DataGrid>
    </div>
  );
};
